<template>
	<div class="total drag" v-draw id="RqAirSupply">
		<!-- 抬头标题 -->
		<div class="flex">
			<div class="title-hang flex">
				<div class="zhuangshizb"></div>
				<div class="zhuangshizj">{{projectData.project}}_4_1</div>
				<div class="zhuangshiyb">
					<div class="icon" @click="closeCompon"></div>
				</div>
			</div>
		</div>
		<div class="air_supply">
			<div class="air_supply_title">
				送风层操调节回路
			</div>
		</div>
		<div class="content">
			<!-- 第一块儿 -->
			<div class="first">
				<div class="bg"></div>
				<div class="line1 flex">
					<div class="table table1 flex">
						<div class="words">GLPV3</div>
						<div class="num" @click="toCompon(2,'GLPV3','MCRANQI','GLPV3_NG3','三层高炉没气量虚拟值')"
							@dblclick="Cclick(infoList.MCRANQI.GLPV3,'GLPV3','GLPV3','MCRANQI')">
							{{infoList.MCRANQI.GLPV3}}
						</div>
					</div>
					<div class="star">*</div>
					<div class="table table2 flex">
						<div class="words">YHFMB11</div>
						<div class="num" @click="toCompon(2,'YHFMB_B11','MCBCS11','YHFMB_B11_NG3','优化风煤比')"
							@dblclick="Cclick(infoList.MCBCS11.YHFMB_B11,'YHFMB11','YHFMB_B11','MCBCS11')">
							{{infoList.MCBCS11.YHFMB_B11}}
						</div>
					</div>
					<div class="table table3 flex">
						<div class="words">SFSP3</div>
						<div class="num" @click="toCompon(2,'SFSP3','MCSONGFENG','SFSP3_NG3','三层空气量设定值')"
							@dblclick="Cclick(infoList.MCSONGFENG.SFSP3,'SFSP3','SFSP3','MCSONGFENG')">
							{{infoList.MCSONGFENG.SFSP3}}
						</div>
					</div>
					<div class="button table4" :style="{
							background:infoList.MCXK__p__XK10 && !infoList.MCXK__p__XK10.RM
					        ? '#2AFC30'
					        : 'red',
							}" @click="infoList.MCXK__p__XK10
							? toDetail(1, 'XK10', 'MCXK__p__XK10', '', '三层空气层操先控调节'): ''">
						X
					</div>
					<div class="button table5" :style="{
					     	background:infoList.MCMAN__p__MAN17.RM && infoList.MCMAN__p__MAN17.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MCMAN__p__MAN17
					     	? toCompon(0,'AV','MCMAN__p__MAN17','MAN17_AV_NG3','三层操空气手操器MAN17','KF_117_B','MCSOUREC','KF_117_NG3'): ''">
						A
					</div>
					<div class="table table6 flex">
						<div class="words">B02-A03</div>
						<div class="num" @click="toCompon(2,'AV','MCMAN__p__MAN17','MAN17_AV_NG3','三层操空气手操器')"
							@dblclick="Cclick(infoList.MCMAN__p__MAN17.AV,'B02-A03','AV','MCMAN__p__MAN17')">
							{{infoList.MCMAN__p__MAN17.AV}}
						</div>
					</div>
				</div>
				<div class="line2 flex">
					<div class="table1 flex">
						<div class="words">ZLPV3</div>
						<div class="num" @click="toCompon(2,'ZLPV3','MCRANQI','ZLPV3_NG3','三层转炉没气量虚拟值')"
							@dblclick="Cclick(infoList.MCRANQI.ZLPV3,'ZLPV3','ZLPV3','MCRANQI')">
							{{infoList.MCRANQI.ZLPV3}}
						</div>
					</div>
					<div class="star">*</div>
					<div class="table2 flex">
						<div class="words">ZL-GL</div>
					</div>
					<div class="star">*</div>
					<div class="table3 flex">
						<div class="words">YHFMB11</div>
						<div class="num" @click="toCompon(2,'YHFMB_B11','MCBCS11','YHFMB_B11_NG3','优化风煤比')"
							@dblclick="Cclick(infoList.MCBCS11.YHFMB_B11,'YHFMB11','YHFMB_B11','MCBCS11')">
							{{infoList.MCBCS11.YHFMB_B11}}
						</div>
					</div>
					<div class="table table4 flex">
						<div class="words">SFPV3</div>
						<div class="num" @click="toCompon(2,'SFPV3','MCSONGFENG','SFPV3_NG3','三层空气量设定值')"
							@dblclick="Cclick(infoList.MCSONGFENG.SFPV3,'SFPV3','SFPV3','MCSONGFENG')">
							{{infoList.MCSONGFENG.SFPV3}}
						</div>
					</div>
					<div class="buttonk table4" :style="{
							background:infoList.MCQK__p__QK5 && !infoList.MCQK__p__QK5.TS
                            ? '#2AFC30'
                            : 'red',}" @click="infoList.MCQK__p__QK5
							    ? toDetail(2,'QK5','MCQK__p__QK5','','送风三层空气层操前馈'): ''">
						K
					</div>
				</div>
				<div class="line2 flex">
					<div class="table1 flex">
						<div class="words">JLPV3</div>
						<div class="num" @click="toCompon(2,'JLPV3','MCRANQI','JLPV3_NG3','三层焦炉没气量虚拟值')"
							@dblclick="Cclick(infoList.MCRANQI.JLPV3,'JLPV3','JLPV3','MCRANQI')">
							{{infoList.MCRANQI.JLPV3}}
						</div>
					</div>
					<div class="star">*</div>
					<div class="table2">
						<div class="words">JL-GL</div>
					</div>
					<div class="star">*</div>
					<div class="table3 flex">
						<div class="words">YHFMB11</div>
						<div class="num" @click="toCompon(2,'YHFMB_B11','MCBCS11','YHFMB_B11_NG3','优化风煤比')"
							@dblclick="Cclick(infoList.MCBCS11.YHFMB_B11,'YHFMB11','YHFMB_B11','MCBCS11')">
							{{infoList.MCBCS11.YHFMB_B11}}
						</div>
					</div>
				</div>
			</div>
			<!-- 第二块儿 -->
			<div class="first second">
				<div class="bg"></div>
				<div class="line1 flex">
					<div class="table table1 flex">
						<div class="words">GLPV2</div>
						<div class="num" @click="toCompon(2,'GLPV2','MCRANQI','GLPV2_NG3','二层高炉没气量虚拟值')"
							@dblclick="Cclick(infoList.MCRANQI.GLPV2,'GLPV2','GLPV2','MCRANQI')">
							{{infoList.MCRANQI.GLPV2}}
						</div>
					</div>
					<div class="star">*</div>
					<div class="table table2 flex">
						<div class="words">YHFMB11</div>
						<div class="num" @click="toCompon(2,'YHFMB_B11','MCBCS11','YHFMB_B11_NG3')"
							@dblclick="Cclick(infoList.MCBCS11.YHFMB_B11,'YHFMB11','YHFMB_B11','MCBCS11')">
							{{infoList.MCBCS11.YHFMB_B11}}
						</div>
					</div>
					<div class="table table3 flex">
						<div class="words">SFSP2</div>
						<div class="num" @click="toCompon(2,'SFSP2','MCSONGFENG','SFSP2_NG3','二层空气量设定值')"
							@dblclick="Cclick(infoList.MCSONGFENG.SFSP2,'SFSP2','SFSP2','MCSONGFENG')">
							{{infoList.MCSONGFENG.SFSP2}}
						</div>
					</div>
					<div class="button table4" :style="{
							background:infoList.MCXK__p__XK9 && !infoList.MCXK__p__XK9.RM
					        ? '#2AFC30'
					        : 'red',
							}" @click="infoList.MCXK__p__XK9
							? toDetail(1, 'XK9', 'MCXK__p__XK9', '', '二层空气层操先控调节'): ''">
						X
					</div>
					<div class="button table5" :style="{
					     	background:infoList.MCMAN__p__MAN16.RM && infoList.MCMAN__p__MAN16.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MCMAN__p__MAN16
					     	? toCompon(0,'AV','MCMAN__p__MAN16','MAN16_RM_NG3','二层操空气手操器MAN16','KF_116_B','MCSOUREC','KF_116_NG3'): ''">
						A
					</div>
					<div class="table table6 flex">
						<div class="words">B02-A02</div>
						<div class="num" @click="toCompon(2,'AV','MCMAN__p__MAN16','MAN16_AV_NG3','二层操空气手操器')"
							@dblclick="Cclick(infoList.MCMAN__p__MAN16.AV,'B02-A02','AV','MCMAN__p__MAN16')">
							{{infoList.MCMAN__p__MAN16.AV}}
						</div>
					</div>
				</div>
				<div class="line2 flex">
					<div class="table1 flex">
						<div class="words">ZLPV2</div>
						<div class="num" @click="toCompon(2,'ZLPV2','MCRANQI','ZLPV2_NG3','二层转炉没气量虚拟值')"
							@dblclick="Cclick(infoList.MCRANQI.ZLPV2,'ZLPV2','ZLPV2','MCRANQI')">
							{{infoList.MCRANQI.ZLPV2}}
						</div>
					</div>
					<div class="star">*</div>
					<div class="table2 flex">
						<div class="words">ZL-GL</div>
					</div>
					<div class="star">*</div>
					<div class="table3 flex">
						<div class="words">YHFMB11</div>
						<div class="num" @click="toCompon(2,'YHFMB_B11','MCBCS11','YHFMB_B11_NG3','优化风煤比')"
							@dblclick="Cclick(infoList.MCBCS11.YHFMB_B11,'YHFMB11','YHFMB_B11','MCBCS11')">
							{{infoList.MCBCS11.YHFMB_B11}}
						</div>
					</div>
					<div class="table table4 flex">
						<div class="words">SFPV2</div>
						<div class="num" @click="toCompon(2,'SFPV1','MCSONGFENG','SFPV1_NG3','一层空气量设定值')"
							@dblclick="Cclick(infoList.MCSONGFENG.SFPV1,'SFPV2','SFPV1','MCSONGFENG')">
							{{infoList.MCSONGFENG.SFPV1}}
						</div>
					</div>
					<div class="buttonk table4" :style="{
							background:infoList.MCQK__p__QK4 && !infoList.MCQK__p__QK4.TS
					        ? '#2AFC30'
					        : 'red',}" @click="infoList.MCQK__p__QK4
							    ? toDetail(2,'QK4','MCQK__p__QK4','','送风二层空气层操前馈'): ''">
						K
					</div>
				</div>
				<div class="line2 flex">
					<div class="table1 flex">
						<div class="words">JLPV2</div>
						<div class="num" @click="toCompon(2,'JLPV2','MCRANQI','JLPV2_NG3','二层焦炉没气量虚拟值')"
							@dblclick="Cclick(infoList.MCRANQI.JLPV2,'JLPV2','JLPV2','MCRANQI')">
							{{infoList.MCRANQI.JLPV2}}
						</div>
					</div>
					<div class="star">*</div>
					<div class="table2 flex">
						<div class="words">JL-GL</div>
					</div>
					<div class="star">*</div>
					<div class="table3 flex">
						<div class="words">YHFMB11</div>
						<div class="num" @click="toCompon(2,'YHFMB_B11','MCBCS11','YHFMB_B11_NG3','优化风煤比')"
							@dblclick="Cclick(infoList.MCBCS11.YHFMB_B11,'YHFMB11','YHFMB_B11','MCBCS11')">
							{{infoList.MCBCS11.YHFMB_B11}}
						</div>
					</div>
				</div>
			</div>
			<!-- 第三块儿 -->
			<div class="first second">
				<div class="bg"></div>
				<div class="line1 flex">
					<div class="table table1 flex">
						<div class="words">GLPV1</div>
						<div class="num" @click="toCompon(2,'GLPV1','MCRANQI','GLPV1_NG3','一层高炉没气量虚拟值')"
							@dblclick="Cclick(infoList.MCRANQI.GLPV1,'GLPV1','GLPV1','MCRANQI')">
							{{infoList.MCRANQI.GLPV1}}
						</div>
					</div>
					<div class="star">*</div>
					<div class="table table2 flex">
						<div class="words">YHFMB11</div>
						<div class="num" @click="toCompon(2,'YHFMB_B11','MCBCS11','YHFMB_B11_NG3','优化风煤比')"
							@dblclick="Cclick(infoList.MCBCS11.YHFMB_B11,'YHFMB11','YHFMB_B11','MCBCS11')">
							{{infoList.MCBCS11.YHFMB_B11}}
						</div>
					</div>
					<div class="table table3 flex">
						<div class="words">SFSP1</div>
						<div class="num" @click="toCompon(2,'SFSP1','MCSONGFENG','SFSP1_NG3','一层空气量设定值')"
							@dblclick="Cclick(infoList.MCSONGFENG.SFSP1,'SFSP1','SFSP1','MCSONGFENG')">
							{{infoList.MCSONGFENG.SFSP1}}
						</div>
					</div>
					<div class="button table4" :style="{
							background:infoList.MCXK__p__XK8 && !infoList.MCXK__p__XK8.RM
					        ? '#2AFC30'
					        : 'red',
							}" @click="infoList.MCXK__p__XK8
							? toDetail(1, 'XK8', 'MCXK__p__XK8', '', '一层空气层操先控调节'): ''">
						X
					</div>
					<div class="button table5" :style="{
					     	background:infoList.MCMAN__p__MAN15.RM && infoList.MCMAN__p__MAN15.RM == 1
					        ? '#2AFC30'
					        : 'red',
					     	}" @click="infoList.MCMAN__p__MAN15
					     	? toCompon(0,'AV','MCMAN__p__MAN15','MAN15_AV_NG3','一层操空气手操器MAN15','KF_115_B','MCSOUREC','KF_115_NG3'): ''">
						A
					</div>
					<div class="table table6 flex">
						<div class="words">B02-A01</div>
						<div class="num" @click="toCompon(2,'AV','MCMAN__p__MAN15','MAN15_AV_NG3','一层操空气手操器')"
							@dblclick="Cclick(infoList.MCMAN__p__MAN15.AV,'B02-A01','AV','MCMAN__p__MAN15')">
							{{infoList.MCMAN__p__MAN15.AV}}
						</div>
					</div>
				</div>
				<div class="line2 flex">
					<div class="table1 flex">
						<div class="words">ZLPV1</div>
						<div class="num" @click="toCompon(2,'ZLPV1','MCRANQI','ZLPV1_NG3','一层转炉没气量虚拟值')"
							@dblclick="Cclick(infoList.MCRANQI.ZLPV1,'ZLPV1','ZLPV1','MCRANQI')">
							{{infoList.MCRANQI.ZLPV1}}
						</div>
					</div>
					<div class="star">*</div>
					<div class="table2 flex">
						<div class="words">ZL-GL</div>
					</div>
					<div class="star">*</div>
					<div class="table3 flex">
						<div class="words">YHFMB11</div>
						<div class="num" @click="toCompon(2,'YHFMB_B11','MCBCS11','YHFMB_B11_NG3','优化风煤比')"
							@dblclick="Cclick(infoList.MCBCS11.YHFMB_B11,'YHFMB11','YHFMB_B11','MCBCS11')">
							{{infoList.MCBCS11.YHFMB_B11}}
						</div>
					</div>
					<div class="table table4 flex">
						<div class="words">SFPV2</div>
						<div class="num" @click="toCompon(2,'SFPV1','MCSONGFENG','SFPV1_NG3','一层空气量设定值')"
							@dblclick="Cclick(infoList.MCSONGFENG.SFPV1,'SFPV2','SFPV1','MCSONGFENG')">
							{{infoList.MCSONGFENG.SFPV1}}
						</div>
					</div>
					<div class="buttonk table4" :style="{
							background:infoList.MCQK__p__QK3 && !infoList.MCQK__p__QK3.TS
					        ? '#2AFC30'
					        : 'red',}" @click="infoList.MCQK__p__QK3
							    ? toDetail(2,'QK3','MCQK__p__QK3','','送风一层空气层操前馈'): ''">
						K
					</div>
				</div>
				<div class="line2 flex">
					<div class="table1 flex">
						<div class="words">JLPV1</div>
						<div class="num" @click="toCompon(2,'JLPV1','MCRANQI','JLPV1_NG3','一层焦炉没气量虚拟值')"
							@dblclick="Cclick(infoList.MCRANQI.JLPV1,'JLPV1','JLPV1','MCRANQI')">
							{{infoList.MCRANQI.JLPV1}}
						</div>
					</div>
					<div class="star">*</div>
					<div class="table2 flex">
						<div class="words">JL-GL</div>
					</div>
					<div class="star">*</div>
					<div class="table3 flex">
						<div class="words">YHFMB11</div>
						<div class="num" @click="toCompon(2,'YHFMB_B11','MCBCS11','YHFMB_B11_NG3','优化风煤比')"
							@dblclick="Cclick(infoList.MCBCS11.YHFMB_B11,'YHFMB11','YHFMB_B11','MCBCS11')">
							{{infoList.MCBCS11.YHFMB_B11}}
						</div>
					</div>
				</div>
			</div>
		</div>
		<inputVal ref="inputVal" @getTreeData='DataJson(arguments)'></inputVal>
		<Historical v-if="isHshow" @sendStatus = 'isHshow=false' :historyname = 'historyname' :node='node' :Lkname='Lkname' 
		:chName="chName" :infoList = 'infoList'></Historical>
		<!-- <Historical v-if="isHshow" @sendStatus="isClose" :historyname="historyname" :node="nodename" :Lkname="Aname"
			:chName="chName" :infoList="infoList"></Historical> -->
		<Firstcontrol :infoList='infoList' :isIndex='isIndex' :historyname='Firstcontrolname'
			:titname='Firstcontroltitname' :node='Firstcontrolnode' v-if="isFshow" @sendStatus='isFshow=false'>
		</Firstcontrol>
		<Rsf v-if="isRshow" @sendStatus='isRshow=false' :titname='Rsftitname' :infoList='infoList'
			:historyname='Rsfname' :node='Rsfnode'></Rsf>
		<ParameterYh v-if="isComShow" :infoList='infoList' @sendStatus='isshowfase'></ParameterYh>
		<Manual :key="isIndex" v-if="isMshow" @sendStatus="isMshow = false" :historyname="Manualname" :node="Manualnode"
			:Lkname="ManualAname" :pmark="Panualmark" :pnode="Panualnode" :pnm="Panualnodem" :titname="Manualtitname"
			:infoList="infoList">
		</Manual>
	</div>
</template>

<script>
	import inputVal from "@/components/inputVal.vue"; //输入框组件
	import Historical from "@/components/Historical.vue" //历史趋势
	import Manual from "@/components/Manual.vue"; //手操器组件
	export default {
		name: "Param11",
		props: {
			infoList: {
				type: Object,
				default: {} // 默认值
			}
		},
		components: {
			inputVal,
			Historical,
			Manual
		},
		data: () => {
			return {
				chName: '',
				Panualmark: '',
				Panualnode: '',
				Panualnodem: '',
				Manualname: "",
				Manualnode: "",
				ManualAname: "",
				Manualtitname: "",
				isComShow: false,
				isHshow: false,
				isMshow: false,
				isRshow: false,
				historyname: '',
				node: '',
				Lkname: '',
				isIndex: '',
				Firstcontrolname: '',
				Firstcontroltitname: '',
				Firstcontrolnode: '',
				isFshow: false,
				Rsfname: '',
				Rsfnode: '',
				Rsftitname: '',
				projectData: '',
				grouptime: null,
				spotArr: [],
				authInfo: [],
				UserInfo: [],
			}

		},
		created() {
			this.projectData = JSON.parse(localStorage.getItem("deviceType"))
			this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
			this.authInfo = JSON.parse(localStorage.getItem("autharr"))
			this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
		},
		methods: {
			DataJson(data) {
				let strNode = data[0]
				let strMark = data[1]
				this.$nextTick(() => {
					this.infoList[strNode][strMark] = data[2]
				});
			},
			// 打开先控多窗口
			toDetail(key, name, nodename, Aname, titname) {
				if (!this.authInfo[1]) {
					let str = this.authInfo[0]
					// 判断是否为调试还是查看权限
					if (str.charAt(str.length - 1) == "r") {
						this.$message.error('暂无调试权限');
					}
				} else {
					let pathname = ""
					switch (key) {
						case 1:
							pathname = 'firstcontol'
							break
						case 2:
							pathname = 'rqkfirstcontol'
							break
						case 3:
							pathname = 'RqRfirstcontol'
							break
					}
					const newurl = this.$router.resolve({
						name: pathname,
						query: {
							Firstcontrolname: name,
							Firstcontrolnode: nodename,
							Firstcontroltitname: titname
						}
					})
					window.open(newurl.href, '_blank')
				}
			},
			// 双击事件
			Cclick(num, mark, node, name) {
				// 组装数据
				clearTimeout(this.grouptime);
				let spojobj = {
					num: num,
					mark: mark,
					node: node,
					name: name
				}
				this.spotArr.push(spojobj)
				// 数组去重
				let deWeightThree = () => {
					let map = new Map();
					for (let item of this.spotArr) {
						if (!map.has(item.name)) {
							this.$message.closeAll()
							map.set(item.name, item);
							this.$message.success('已添加');
						} else {
							this.$message.closeAll()
							this.$message.error('已添加到变量池里，切勿重复添加');
						}
					}

					return [...map.values()];
				}
				this.spotArr = deWeightThree();
				localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
			},
			isshowfase() {
				this.isComShow = false
			},
			closeCompon() {

				this.$emit('sendStatus', 'Param041', false)
			},
			isClose(val, val1) {
				switch (val) {
					case 'Historical':
						return this.isHshow = val1
				}
			},
			toIpt(data, name, historyname, node) {

				this.$refs.inputVal.open(data, name, historyname, node)
			},
			toCompon(key, name, name2, name3,name4, titname, param1, param2, param3) {
				this.isIndex = key
				this.historyname = name
				this.node = name2
				this.Lkname = name3
				clearTimeout(this.grouptime);
				this.grouptime = setTimeout(() => {
					switch (key) {
						case 0:
							return this.isMshow = true, this.Manualname = name, this.Manualnode = name2, this
								.ManualAname = name3, this.Manualtitname = titname, this.Panualmark = param1, this
								.Panualnode = param2, this.Panualnodem = param3
						case 1:
							return this.isFshow = true, this.Firstcontrolname = name, this.Firstcontrolnode =
								name2, this.Firstcontroltitname = titname
						case 2:
							return this.isHshow = true, this.chName = name4
						case 4:
							return this.isRshow = true, this.Rsfname = name, this.Rsfnode = name2, this
								.Rsftitname = titname
					}
				}, 300);
			},
		}
	}
</script>

<style lang="scss" scoped>
	.total {
		width: 53vw;
		height: 67vh;
		background-image: url("~@/assets/images/RqBoiler/servive_bg.png");
		background-size: 100% 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -33.5vh 0 0 -26.5vw;
		z-index: 999;

		.title-hang {
			width: 53vw;
			height: 5vh;
			font-family: MicrosoftYaHei;
			font-size: 1vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;
		}

		.zhuangshizb {
			width: 16vw;
			height: 2vh;
			background-image: url("~@/assets/images/rfl_evaluate06.png");
			background-size: 100% 100%;
			margin-top: 2vh;
		}

		.zhuangshizj {
			width: 19vw;
			height: 5vh;
			font-family: MicrosoftYaHei;
			font-size: 1.3vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 5vh;
			letter-spacing: 0vh;
			color: #0ef7ff;
			margin-top: 1vh;
			text-align: center;
			background-image: url("~@/assets/images/rfl_evaluate05.png");
			background-size: 100% 100%;
			margin-left: 1vw;
		}

		.zhuangshiyb {
			width: 16vw;
			height: 2vh;
			background-image: url("~@/assets/images/rfl_evaluate04.png");
			background-size: 100% 100%;
			margin-left: 1vw;
			margin-top: 2vh;
		}

		.icon {
			width: 1.9vw;
			height: 3.2vh;
			background-image: url("~@/assets/images/RqBoiler/icon_close1.png");
			background-size: 100% 100%;
			margin-left: 13vw;
			margin-top: -0.5vh;
			cursor: pointer;
		}

		.air_supply {
			width: 53vw;
			height: 2vh;
			margin-top: 2vh;

			.air_supply_title {
				font-family: PingFang-SC-Regular;
				font-size: 1.8vw;
				font-weight: normal;
				font-stretch: normal;
				line-height: 2vh;
				letter-spacing: 1vh;
				color: #d5fffe;
				text-align: center;
			}
		}

		.content {
			width: 50vw;
			margin-left: 2vw;
			margin-top: 3vh;
			font-family: PingFang-SC-Regular;
			font-size: 0.8vw;
			font-weight: normal;
			font-stretch: normal;
			line-height: 2vh;
			letter-spacing: 0vh;

			.button {
				width: 1vw;
				height: 2vh;
				border: 2px solid rgb(217, 243, 145);
				text-align: center;
				line-height: 2vh;
				color: white;
				cursor: pointer;
			}

			.first {
				height: 14vh;
				border: 0px solid rgba(0, 0, 0, 0);

				.bg {
					margin-left: 16vw;
					width: 24vw;
					height: 15vh;
					background-image: url("~@/assets/images/RqBoiler/RqOperationAssessment/air_supply.png");
					background-size: 100% 100%;
				}

				.star {
					width: 1vw;
					height: 2vh;
					font-family: PingFang-SC-Regular;
					font-size: 1vw;
					font-weight: normal;
					font-stretch: normal;
					line-height: 3vh;
					letter-spacing: 0vh;
					color: #8aeaff;
					text-align: center;
				}

				.table {
					width: 8vw;
					height: 2vh;
					background-color: #236f8d;
					border-radius: 0vh;
					border: solid 1px #00e4ff;
				}

				.line1 {
					width: 50vw;
					height: 2vh;
					margin-top: -14vh;

					.table1 {
						.words {
							margin-left: 0.5vw;
							width: 3.8vw;
							color: #8aeaff;
						}

						.num {
							margin-left: 0vw;
							width: 2vw;
							color: #8aeaff;
							text-align: right;
							cursor: pointer;
						}
					}

					.table2 {
						.words {
							margin-left: 0.5vw;
							width: 4vw;
							color: #8aeaff;
						}

						.num {
							margin-left: 0.5vw;
							width: 2vw;
							color: #8aeaff;
							text-align: right;
							cursor: pointer;
						}
					}

					.table3 {
						margin-left: 5.8vw;

						.words {
							margin-left: 0.5vw;
							width: 4vw;
							color: #8aeaff;
						}

						.num {
							margin-left: 0vw;
							width: 2vw;
							color: #8aeaff;
							text-align: right;
							cursor: pointer;
						}
					}

					.buttonx {
						width: 1vw;
						height: 2vh;
						border: 2px solid rgb(217, 243, 145);
						text-align: center;
						line-height: 2vh;
						background-color: #00e4ff;
						color: white;
						cursor: pointer;
					}

					.table4 {
						margin-left: 2.2vw;
					}

					.table5 {
						margin-left: 5.5vw;

					}

					.table6 {
						margin-left: 0.5vw;

						.words {
							margin-left: 0.5vw;
							width: 4vw;
							color: #8aeaff;
						}

						.num {
							margin-left: 0.5vw;
							width: 2vw;
							color: #8aeaff;
							text-align: right;
							cursor: pointer;
						}
					}
				}

				.line2 {
					margin-top: 3.5vh;

					.table1 {
						width: 6vw;
						height: 2vh;
						background-color: #236f8d;
						border-radius: 0vh;
						border: solid 1px #00e4ff;

						.words {
							margin-left: 0.5vw;
							width: 2vw;
							color: #8aeaff;
						}

						.num {
							margin-left: 0.5vw;
							width: 3vw;
							color: #8aeaff;
							text-align: right;
							cursor: pointer;
						}
					}

					.table2 {
						width: 3vw;
						height: 2vh;
						background-color: #236f8d;
						border-radius: 0vh;
						border: solid 1px #00e4ff;

						.words {
							margin-left: 0.5vw;
							width: 3vw;
							color: #8aeaff;
						}
					}

					.table3 {
						width: 6vw;
						height: 2vh;
						background-color: #236f8d;
						border-radius: 0vh;
						border: solid 1px #00e4ff;

						.words {
							margin-left: 0vw;
							width: 3vw;
							color: #8aeaff;
						}

						.num {
							margin-left: 0.5vw;
							width: 2vw;
							color: #8aeaff;
							text-align: right;
							cursor: pointer;
						}
					}

					.table4 {
						margin-left: 5.7vw;

						.words {
							margin-left: 0.5vw;
							width: 4vw;
							color: #8aeaff;
						}

						.num {
							margin-left: 0vw;
							width: 2vw;
							color: #8aeaff;
							text-align: right;
							cursor: pointer;
						}
					}

					.buttonk {
						width: 1vw;
						height: 2vh;
						border: 2px solid rgb(217, 243, 145);
						text-align: center;
						line-height: 2vh;
						background-color: #04ff57;
						color: white;
						cursor: pointer;
					}
				}
			}

			.second {
				margin-top: 3vh;
			}
		}

	}
</style>
